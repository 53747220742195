//import ReactDOM from "react-dom";
//import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
// import Cardone from "./Components/Cardone";
// import Cardtwo from "./Components/Cardtwo";
import Cardthree from "./Components/Cardthree";
// import Cardfour from "./Components/Cardfour";
// import Cardfive from "./Components/Cardfive";


// export default function App() {
//   return (
//     <BrowserRouter>
//       <Routes>
  
        
//           <Route path="pratik" element={<Cardone />} />
//           <Route path="vilma" element={<Cardtwo />} />
//           <Route path="dhanraj" element={<Cardthree />} />
//           <Route path="prateek" element={<Cardfour />} />
//           <Route path="firas" element={<Cardfive />} />

//       </Routes>
//     </BrowserRouter>
//   );
// }

// ReactDOM.render(<App />, document.getElementById("root"));






 function App() {
  return (
    <div>
      <Cardthree />
      </div>
    );
  }

  export default App;